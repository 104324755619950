<template>
    <div id="app">
        <LogIn v-if="!isLoggedIn" @login-success="handleLoginSuccess" />
        <div v-else class="container" id="container">
            <div class="btn-group mb-4" role="group" aria-label="Basic outlined example">
                <button type="button" class="btn btn-primary" @click="currentPage = 0" :class="{ active: currentPage === 0 }">通用设置</button>
                <button type="button" class="btn btn-primary" @click="currentPage = 1" :class="{ active: currentPage === 1 }">脚本设置</button>
                <button type="button" class="btn btn-primary" @click="currentPage = 2" :class="{ active: currentPage === 2 }">更新日志</button>
                <button type="button" class="btn btn-primary" @click="currentPage = 3" :class="{ active: currentPage === 3 }">关于</button>
            </div>

            <i class="bi bi-x-square-fill close" @click="close()"></i>

            <GeneralSettings v-if="currentPage === 0" :username="userId" @update-ball-config="updateBallConfig" />
            <ScriptSettings v-if="currentPage === 1" :username="userId" :ballConfig="ballConfig" />
            <UpdateLog v-else-if="currentPage === 2" />
            <About v-else-if="currentPage === 3" />
        </div>
    </div>
</template>

<script>
import GeneralSettings from './components/pages/GeneralSettings.vue';
import ScriptSettings from './components/pages/ScriptSettings.vue'; // 引入 ScriptSettings
import UpdateLog from './components/pages/UpdateLog.vue';
import About from './components/pages/AboutWe.vue';
import LogIn from './components/pages/LogIn.vue';

export default {
    components: {
        GeneralSettings,
        ScriptSettings, // 注册 ScriptSettings 组件
        UpdateLog,
        About,
        LogIn,
    },
    data() {
        return {
            currentPage: 0,
            isLoggedIn: false,
            userId: null,
            ballConfig: 'selfBuilt', // 用于存储 ballConfig
        };
    },
    methods: {
        close() {
            const platform = window.platform;
            if (platform != null && platform.hide != null) {
                platform.hide();
            }
        },
        handleLoginSuccess(userId) {
            this.isLoggedIn = true;
            this.userId = userId;
        },
        updateBallConfig(newBallConfig) {
            this.ballConfig = newBallConfig; // 更新 ballConfig
        }
    }
};
</script>
