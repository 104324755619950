<template>
    <div v-if="visible" class="modal">
        <div class="modal-content">
            <span class="close" @click="close">&times;</span>
            <p>{{ message }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyModal',
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        message: {
            type: String,
            default: ''
        }
    },
    methods: {
        close() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    height: 155%;
}

.modal-content {
    width: 200px;
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.modal-content > p {
    margin: 0;
}

.close {
    cursor: pointer;
    float: right;
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 23px;
}
</style>
