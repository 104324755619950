<template>
    <div class="update-log">
        <ul>
            <li v-for="version in versions" :key="version.version">
                <h2 class="version">{{ version.version }}</h2>
                <span class="upTime">{{ version.date }}</span>
                <ul class="updates">
                    <li v-for="update in version.updates" :key="update">{{ update }}</li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            versions: [
                { version: '1.0.0', date: '2024-09-11', updates: ['首次发布，包含基础功能。', '用户界面优化。'] },
                { version: '1.1.0', date: '2024-09-11', updates: ['新增数据导入功能。', '修复了一些已知bug。'] },
                { version: '1.2.0', date: '2024-09-11', updates: ['改进性能，提升加载速度。', '增加用户反馈功能。'] },
                { version: '1.3.0', date: '2024-09-25', updates: ['新增分辨率自适应。', '纠正参数表信息错误。'] },

            ]
        };
    }
};
</script>

<style scoped>
/* 添加样式 */
</style>
